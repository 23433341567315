const monthNames = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
];

function buildHeader(header, curMonth, curYear, callback) {
  const monthDrop = document.createElement('select');
  for (let i = 0; i < monthNames.length; i++) {
    const item = document.createElement('option');
    item.setAttribute('value', String(i + 1));
    if (i === curMonth - 1) {
      item.setAttribute('selected', 'selected');
    }

    item.appendChild(document.createTextNode(monthNames[i]));
    monthDrop.appendChild(item);
  }

  const yearDrop = document.createElement('select');
  const now = new Date();
  for (let y = now.getFullYear(); y < now.getFullYear() + 2; y++) {
    const item = document.createElement('option');
    item.setAttribute('value', String(y));
    if (y === curYear) {
      item.setAttribute('selected', 'selected');
    }

    item.appendChild(document.createTextNode(y));
    yearDrop.appendChild(item);
  }

  header.innerHTML = '';
  header.appendChild(monthDrop);
  header.appendChild(yearDrop);

  monthDrop.addEventListener('change', (e) => {
    curMonth = parseInt(monthDrop.value, 10);
    callback(curMonth, curYear);
  });

  yearDrop.addEventListener('change', (e) => {
    curYear = parseInt(yearDrop.value, 10);
    callback(curMonth, curYear);
  });
}

async function updateAll(month, year) {
  for (const [element, apiUrl] of calendars) {
    let response;
    try {
      response = await fetch(`${apiUrl}&month=${month}&year=${year}`);

      if (response.status === 200) {
        element.innerHTML = await response.text();
        buildHeader(element.querySelector('.header'), month, year, updateAll);
      } else {
        alert('Fehler: Der Kalender konnte nicht geladen werden, bitte versuchen Sie es später erneut.');
        return;
      }
    } catch (e) {
      console.error(e);
      alert('Fehler: Es konnte keine Anfrage an die Webseite gesendet werden!');
      return;
    }
  }
}

let calendars = [];
window.prepare_calendar = (elID, apiUrl, initMonth) => {
	const element = document.querySelector(`#${elID}`);
	if (!element) {
		return;
	}

  const now = new Date();
	async function update(month, year) {
    let response;
    try {
      response = await fetch(`${apiUrl}&month=${month}&year=${year}`);

      if (response.status === 200) {
        element.innerHTML = await response.text();
        buildHeader(element.querySelector('.header'), month, year, update);
      } else {
        alert('Fehler: Der Kalender konnte nicht geladen werden, bitte versuchen Sie es später erneut.');
      }
    } catch (e) {
      console.error(e);
      alert('Fehler: Es konnte keine Anfrage an die Webseite gesendet werden!');
    }
  }

  if (!initMonth) {
    initMonth = now.getMonth() + 1;
  }

  const header = element.querySelector('.header');
  if (!header) {
    update(initMonth, now.getFullYear());
  } else {
    buildHeader(element.querySelector('.header'), initMonth, now.getFullYear(), updateAll);
  }
  calendars.push([element, apiUrl]);
};

window.prepare_calendar_year_select = (containerID, apiUrl, initUpdate) => {
  const container = document.querySelector(`#${containerID}`);
  if (!container) {
    return;
  }

  const selector = container.querySelector('.year-select');
  if (!selector) {
    return;
  }

  async function update(year) {
    let response;
    try {
      response = await fetch(`${apiUrl}&year=${year}`);

      if (response.status === 200) {
        const data = await response.json();
        if (!data || !data.months) {
          alert('Fehler: Der Kalender konnte nicht geladen werden, bitte versuchen Sie es später erneut.');
          return;
        }

        const cals = container.querySelectorAll('.calendar');
        for (let i = 0; i < cals.length; i++) {
          cals[i].innerHTML = data.months[i];
        }

        if (initUpdate) {
	  initYearDrop();
	  initUpdate = false;
	}
      } else {
        alert('Fehler: Der Kalender konnte nicht geladen werden, bitte versuchen Sie es später erneut.');
      }
    } catch (e) {
      console.error(e);
      alert('Fehler: Es konnte keine Anfrage an die Webseite gesendet werden!');
    }
  }

  function initYearDrop() {
    const yearDrop = document.createElement('select');
    const now = new Date();
    for (let y = now.getFullYear(); y < now.getFullYear() + 2; y++) {
      const item = document.createElement('option');
      item.setAttribute('value', String(y));
      if (y === now.getFullYear()) {
        item.setAttribute('selected', 'selected');
      }

      item.appendChild(document.createTextNode(y));
      yearDrop.appendChild(item);
    }

    selector.innerHTML = '';
    selector.appendChild(yearDrop);

    yearDrop.addEventListener('change', (e) => {
      const curYear = parseInt(yearDrop.value, 10);
      void update(curYear);
    });
  }

  if (initUpdate) {
    update((new Date()).getFullYear());
  } else {
    initYearDrop();
  }
};

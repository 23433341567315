import Swiper from 'swiper';
import 'swiper/css/bundle';
import SwiperCore, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/core';

SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade]);

if (document.querySelector('.swiper-container') !== null) {
  const swiper = new Swiper('.swiper-container', {
    // direction: 'horizontal',
    loop: true,
    autoplay: {
      delay: 6000,
    },
    speed: 800,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  swiper.autoplay.start();
}
